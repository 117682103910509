import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';

import { UserService } from '@users/domain/services/user.service';
import { UnitConversionService } from '@shared/services/unit-convertion.service';
import { UserCrudService } from '@shared/services/user-crud.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private userPreferencesSubject = new BehaviorSubject<any>(null);
  userPreferences$ = this.userPreferencesSubject.asObservable();
  private userPreferences: any = null;
  private userId: string;

  constructor(
    private userCrudService: UserCrudService,
    private userService: UserService,
    public unitConversionService: UnitConversionService
  ) {
    this.userPreferences$.subscribe((preferences) => {
      this.userPreferences = preferences;
    });
    this.getPreferences();
  }

  async getPreferences(): Promise<void> {
    const userLoggedIn = await this.userService.getMyUser();
    this.userId = userLoggedIn._id;
    console.log(userLoggedIn)
    this.userPreferencesSubject.next(userLoggedIn?.preference);
  }

  public async updatePreferences(preference: { unit: string }): Promise<void> {
    const userLoggedIn = await this.userCrudService.update({ _id: this.userId, preference });
    this.userPreferencesSubject.next(userLoggedIn.preference);
  }

  public convertUnitByPreference(value: number | string, unitType: string): number {
    value = Number(value);
    console.log(this.userPreferences);
    if (isNaN(value)) {
      return 0;
    }
    try {
      const fromSystem = 'metric';
      const toSystem = this.userPreferences?.unit ?? 'metric';

      return Number(this.unitConversionService.convert(value, unitType, fromSystem, toSystem)?.toFixed(1));
    } catch (e) {
      console.error(e);
      return value;
    }
  }

  public revertUnitByPreference(value: number | string, unitType: string): number {
    value = Number(value);
    if (isNaN(value)) {
      return 0;
    }
    try {
      const fromSystem =this.userPreferences?.unit ?? 'metric';
      const toSystem = 'metric';

      return Number(this.unitConversionService.convert(value, unitType, fromSystem, toSystem)?.toFixed(1));
    } catch (e) {
      console.error(e);
      return value;
    }
  }

  public getUnitNameByPreference(unitType: string): string {
    if (!unitType || !this.unitConversionService.convertionRatios[unitType]) {
      return unitType;
    }
    const toSystem = this.userPreferences?.unit ?? 'metric';
    return this.unitConversionService.convertionRatios[unitType][`${toSystem}Abbreviation`];
  }
}
