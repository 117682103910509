import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { TokenSessionService } from '@shared/services/token.session.service';
import { LanguageService } from '@shared/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class WebPushNotificationService {

  constructor(private languageService: LanguageService, private http: HttpClient, private tokenSessionService: TokenSessionService) {}
  private backendUrl = `${document.location.protocol}//${document.location.hostname}${environment.apiPort}/web-notification`;


  // Get the VAPID public key from the backend
  getPublicKey(): Promise<string> {
    return this.http.get<{ publicKey: string }>(`${this.backendUrl}/vapid-public-key`).toPromise().then(response => response.publicKey);
  }


  getSubscription(userId: string, userAgent: string): Promise<any> {
    return this.http.post<any>(`${this.backendUrl}/subscriptions/users/${userId}`, {userAgent}).toPromise();
  }


  // Save subscription on the server
  subscribeToNotifications(subscription: any): Observable<any> {
    return this.http.post(`${this.backendUrl}/subscriptions`, subscription);
  }

  // Trigger a test notification from the server
  sendNotification(title: string, message: string, userId: string, userAgent: string): Promise<void> {
    return this.http.post(`${this.backendUrl}/send-notification`, { title, message, userId, userAgent , lang: this.languageService.currentLanguage }).toPromise()as unknown as Promise<void>;
  }

  async createSubscription(onSuccess: () => void) {
    const existingRegistration = await navigator.serviceWorker.getRegistration();
    let swReg: ServiceWorkerRegistration;
    if (!existingRegistration) {
      swReg = await navigator.serviceWorker.register('service-worker.js');
    } else {
      swReg = existingRegistration;
    }
    const publicKey = await this.getPublicKey();
    const applicationServerKey = this.urlBase64ToUint8Array(publicKey);

    const sub = await swReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      });
    const subJson = JSON.parse(JSON.stringify(sub));

    this.subscribeToNotifications({
        userId: this.tokenSessionService.userInfo._id,
        endpoint: subJson.endpoint,
        expirationTime: sub.expirationTime,
        keys: subJson.keys,
        userAgent: window.navigator.userAgent
      }).subscribe(onSuccess);

  }

  private urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  }
}
