import { Injectable } from '@angular/core';

export type UnitType = 'metric' | 'imperial';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {
  private readonly conversionRatios: {
    [key: string]: {
      ratio?: number;
      metricAbbreviation: string;
      imperialAbbreviation: string;
      convert?: (value: number, fromSystem: UnitType, toSystem: UnitType) => number
    }
  } = {
    length_small: { ratio: 25.4, metricAbbreviation: 'mm', imperialAbbreviation: 'in' },
    volume_medium: { ratio: 0.264172, metricAbbreviation: 'dm³', imperialAbbreviation: 'gal' },
    weight_medium: { ratio: 0.00220462, metricAbbreviation: 'g', imperialAbbreviation: 'lb' },
    weight_large: { ratio: 2.20462, metricAbbreviation: 'kg', imperialAbbreviation: 'lb' },
    pressure: { ratio: 14.5038, metricAbbreviation: 'bar', imperialAbbreviation: 'psi' },
    power: { ratio: 1.34102, metricAbbreviation: 'kW', imperialAbbreviation: 'hp' },
    temperature: {
      metricAbbreviation: '°C',
      imperialAbbreviation: '°F',
      convert: (value, fromSystem, toSystem) => {
        if (fromSystem === 'metric' && toSystem === 'imperial') {
          return (value * 9 / 5) + 32;
        } else if (fromSystem === 'imperial' && toSystem === 'metric') {
          return (value - 32) * 5 / 9;
        }
        return value;
      }
    }
  };

  /**
   * Convert a value between metric and imperial units.
   * @param value The value to convert
   * @param unitType The unit type (e.g., 'length_small', 'weight_medium', etc.)
   * @param fromSystem The source system ('metric' or 'imperial')
   * @param toSystem The target system ('metric' or 'imperial')
   * @returns The converted value
   */
  convert(value: number, unitType: string, fromSystem: UnitType, toSystem: UnitType): number {
    const conversion = this.conversionRatios[unitType];

    if (!conversion) {
      throw new Error('Unsupported unit type.');
    }

    if (fromSystem === toSystem) {
      return value;
    }

    if (conversion.convert) {
      return conversion.convert(value, fromSystem, toSystem);
    }

    if (conversion.ratio) {
      return fromSystem === 'metric' && toSystem === 'imperial'
        ? value * conversion.ratio
        : value / conversion.ratio;
    }

    throw new Error('Conversion system not supported.');
  }

  get convertionRatios(): {
    [p: string]: {
      ratio?: number;
      metricAbbreviation: string;
      imperialAbbreviation: string;
      convert?: (value: number, fromSystem: UnitType, toSystem: UnitType) => number
    }
  } {
    return this.conversionRatios;
  }
}
