import * as moment from 'moment';
import { EquipmentConfig } from 'src/app/equipment/domain/equipment-config';

import { Alarm } from './alarm';
import { BaseMultilanguage } from './base-multilanguage.model';
import { CustomerLocation } from './customer-location';
import { Device } from './device';
import { EquipmentStatus } from './equipment-status';
import {UnitFlagsAndAlarms} from "./unit-flags-and-alarms";

export class Equipment extends BaseMultilanguage {
  public customerLocation: CustomerLocation;
  public status: EquipmentStatus;
  public id?: string;
  public childDevicesOfMainDevice: Device[];
  public activeAlarms: Alarm[];
  public equipmentActiveAlarms: Alarm[];
  public equipmentconfig: EquipmentConfig;
  public unit_flags_and_alarms: UnitFlagsAndAlarms;
  public colorDistanceFromRed: number;
  public totalUnsolvedChangeRequests: number;
  public inactive:boolean;
  private _yearlyControlToDoOn: Date;
  public pumpdown_status?:boolean;


  constructor(valuesObj: any) {
    super(valuesObj);
    if (valuesObj.customerLocation) {
      this.customerLocation = new CustomerLocation(valuesObj.customerLocation);
    }
    if (valuesObj.main_device_id) {
      this.main_device_id = new Device(valuesObj.main_device_id);
    }
    if (valuesObj.status) {
      this.status = new EquipmentStatus(valuesObj.status);
    }
    if (valuesObj.childDevicesOfMainDevice && Array.isArray(valuesObj.childDevicesOfMainDevice)) {
      let childDevicesOfMainDevice = [];
      for (const childDeviceData of valuesObj.childDevicesOfMainDevice) {
        childDevicesOfMainDevice.push(new Device(childDeviceData));
      }
      this.childDevicesOfMainDevice = childDevicesOfMainDevice;
    }

    if (valuesObj.activeAlarms && Array.isArray(valuesObj.activeAlarms)) {
      let activeAlarms = [];
      for (const activeAlarmData of valuesObj.activeAlarms) {
        activeAlarms.push(new Alarm(activeAlarmData));
      }
      this.activeAlarms = activeAlarms;
    }

    if (valuesObj.equipmentActiveAlarms && Array.isArray(valuesObj.equipmentActiveAlarms)) {
      let activeAlarms = [];
      for (const activeAlarmData of valuesObj.equipmentActiveAlarms) {
        activeAlarms.push(new Alarm(activeAlarmData));
      }
      this.equipmentActiveAlarms = activeAlarms;
    }

    if (valuesObj.equipmentconfig) {
      this.equipmentconfig = new EquipmentConfig(valuesObj.equipmentconfig);
    }
  }

  public getFirstAlarm(): Alarm {
    const activeAlarms: Alarm[] = Array.isArray(this.activeAlarms)
      ? this.activeAlarms
      : Array.isArray(this.equipmentActiveAlarms)
      ? this.equipmentActiveAlarms
      : [];
    return Array.isArray(activeAlarms) && activeAlarms.length ? activeAlarms[0] : null;
  }

  public getAlarmTypesNames(lang: string, asArray: boolean = false): string[] | string {
    const activeAlarms: Alarm[] = Array.isArray(this.activeAlarms)
      ? this.activeAlarms
      : Array.isArray(this.equipmentActiveAlarms)
      ? this.equipmentActiveAlarms
      : [];

    let alarmTypesNames: string[] = activeAlarms.map((activeAlarm: Alarm) => {
      return activeAlarm.alarm_type.mlVal('name', lang);
    });

    function onlyUnique(value: any, index: any, self: any[]) {
      return self.indexOf(value) === index;
    }
    alarmTypesNames = alarmTypesNames.filter(onlyUnique);

    if (asArray) {
      return alarmTypesNames;
    }
    return alarmTypesNames.join(', ');
  }

  public getYearlyControlToDoOn(): Date {
    if (!this.equipmentconfig || !this.equipmentconfig.general_last_tare_date) {
      return null;
    }

    if (!this._yearlyControlToDoOn) {
      this._yearlyControlToDoOn = moment(this.equipmentconfig.general_last_tare_date).add(1, 'years').toDate();
    }

    return this._yearlyControlToDoOn;
  }

  public hasModuleEnergy(): boolean {
    return this.childDevicesOfMainDevice?.some((device) => device.type === 'energy');
  }
}
