import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { CrudService } from '@shared/services/crud/crud.service';
import { User } from '@users/domain/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserCrudService extends CrudService<User, User, User> {
  constructor() {
    super();
    this.setFragment();
  }

  public fragment: any;
  public modelName = 'User';

  private setFragment(fragment?: any): void {
    this.fragment =
      fragment ||
      gql`
        fragment fields on ${this.modelName} {
          _id
          name
          preference {
          unit
          }
        }
      `;
  }
}
